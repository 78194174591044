import React, {useCallback, useEffect, useRef, useState} from "react";
import {PropsDiamondFilter, PropsFromScreenManager, TableData, TabsProps} from "../../types";
import Table from "../Table";
import {
    Round,
    Asscher,
    Emerald,
    Heart,
    Oval,
    Pear,
    Princess,
    Radiant, Marquise, Elongated, Cushion,
} from "../Images";
import {ToLocalStringConvert} from "../../Utils/toLocalStringConvert";
import {TooltipDataFetch} from "../Tooltip/TooltipDataFetch";
import {useMediaQuery} from "react-responsive";
import {DiamondShapeContent} from "../../Utils/commonData";

export const source: string[] = ["Natural","Lab Grown"];
const color: string[] = ["J", "I", "H", "G", "F", "E", "D"];
const clarity: string[] = ["SI2", "SI1", "VS2", "VS1", "VVS2", "VVS1", "IF", "FL"];
const cut: string[] = ["Fair", "Good", "Very Good", "Excellent"];
const fluorecence: string[] = ["Very Strong", "Strong", "Medium", "Faint", "None"];
const polish: string[] = ["Fair", "Good", "Very Good", "Excellent"];
const symmetry: string[] = ["Fair", "Good", "Very Good", "Excellent"];
export const propertyMappings: Record<string, string[]> = {
    color: color,
    clarity: clarity,
    cut: cut,
    fluorecence: fluorecence,
    polish: polish,
    symmetry: symmetry,
};
export const shapes: Record<string, string> = {
    Round: Round,
    Emerald: Emerald,
    Oval: Oval,
    Pear: Pear,
    Radiant: Radiant,
    Princess: Princess,
    Marquise: Marquise,
    Elongated: Elongated,
    Cushion: Cushion,
    Asscher: Asscher,
    Heart: Heart
}
const useDiamond = (props: PropsDiamondFilter & PropsFromScreenManager) => {
    const {
        filter,
        table,
        footer,
        diamond,
        tableDataLoading,
        tableDataSuccess,
        tableDataFail,
        recommendedDataLoading,
        recommendedDataSuccess,
        recommendedDataFail,
        setFilterMinMaxProperty,
        // setChangeMinMaxValue,
        ring,
        setInitialOption,
        setBackDiamondDetails,
        SetTooltipDataSuccess,
        setTooltipDataLoading,
        SetTooltipDataError,
        resetFilters,
        setInitialFilterAfterRingOptions,
        setIsRefetching,
        setSorting,
        setPagination,
        instanceData,
        setActiveTab,
        setBackToSelectDiamondPage,
        setRecommendedDataDefault,
        setFilter,
        toggleScrollTop
    } = props;
    const [isChangeFilterOptions,setIsChangeFilterOptions] = useState(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const hasEffectRun = useRef(false);
    const isMobileLayout = useMediaQuery({ query: "(max-width: 767px)" });
    const [tableSorting, setTableSorting] = useState(table.sorting);
    const [isPageLoad,setIsPageLoad] = useState(false);
    useEffect(() => {
      setIsPageLoad(true);
      return ()=> {
        setIsPageLoad(false);
      }
    }, []);
    useEffect(() => {
        if((table.activeTab === 0 || diamond.isBackDiamondPage) && isPageLoad){
            tableDataLoading();
            getTableData();
        }
    }, [isPageLoad,isChangeFilterOptions,table.pagination[table.activeTab].pageIndex,table.pagination[table.activeTab].pageSize])

    useEffect(()=>{
        if(!diamond.isBackDiamondPage){
            if(table.pagination[table.activeTab].pageIndex === 0){
                setIsChangeFilterOptions(toggle => !toggle);
            }
            setPagination(table.activeTab,{pageSize : table.pagination[table.activeTab].pageSize,pageIndex:0})
        }
    },[diamond.isBackDiamondPage,table.sorting,filter.options])

    useEffect(()=>{
        setSorting(tableSorting);
    },[tableSorting])

    const recommendData = useCallback(async () => {
        // const queryParams = new URLSearchParams(filter.options as Record<string, string>).toString();
        recommendedDataLoading();
        const queryParams = new URLSearchParams({...(filter.options.shape && { shape: filter.options.shape }),...(filter.options.source && { source: filter.options.source }),...(filter.options.carat && { carat: filter.options.carat })} as Record<string, string>).toString();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_KEY}?filter=recommended&${queryParams}`);
            if (!response.ok) {
                recommendedDataFail(new Error(`HTTP error! Status: ${response.status}`));
            }
            const {data: result} = await response.json();
            result.forEach(updateTableItem);
            recommendedDataSuccess(result);
        } catch (e) {
            recommendedDataFail(e);
        }
    }, [filter.options, recommendedDataFail, recommendedDataSuccess, recommendedDataLoading, fetch]);

    const getTableData = useCallback(async () => {
        let allFilterOptions:{[key:string] : string | string [] | boolean} = {};
        if (table.tableAllData.length) {
            setIsRefetching(true);
        }
        if(table.sorting.length > 0){
            const sortObj = table.sorting[0];
            const newSortObj = {
                sort_field : sortObj.id,
                sort_order: sortObj.desc ? 'DESC' : 'ASC'
            }
            allFilterOptions = Object.assign({},filter.options,newSortObj)
        }else {
            allFilterOptions = Object.assign({},filter.options)
        }
      const contents = DiamondShapeContent.filter(item => !ring.exclusionOption.includes(item.code));
      if(contents.length > 0 && contents && footer.isRedirectToEdit && !filter.options["shape"]){
        allFilterOptions["shape"] = contents.map(content => content.code)
      }
        const queryParams = new URLSearchParams(allFilterOptions as Record<string, string>).toString();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_KEY}?${queryParams}&page=${table.pagination[0].pageIndex + 1}&page_size=${table.pagination[0].pageSize}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const {data: result, minMax, total_count} = await response.json();
            tableDataSuccess(result);
            setTotalCount(total_count);
            result.forEach(updateTableItem);
            // if (filter.isMinMaxChangeValue) {
                setFilterMinMaxProperty(minMax);
                // setChangeMinMaxValue(false);
            // }
            setIsRefetching(false);
        } catch (error) {
            console.error(error);
            tableDataFail(error);
        }
    }, [filter.isMinMaxChangeValue,setIsRefetching, setFilterMinMaxProperty, filter.options, fetch, tableDataLoading, tableDataFail, tableDataSuccess,setTotalCount,table.pagination,table.sorting]);

    const updateTableItem = (item: TableData) => {
        Object.keys(propertyMappings).forEach((property) => {
            if (item[property]) {
                item[property] = propertyMappings[property][item[property] as number - 1];
            }
        });
        /*Object.entries(item).forEach(([key,value]) => {
            item[key] = item[key] ? item[key] : '-'
        });*/

        if (typeof item.shape !== "boolean" && item.shape) {
            item.img = shapes[item.shape];
        }
        item.source = item.source === 'GNU' ? source[0] : source[1];
    };


    useEffect(() => {
        if (ring.isBackToDiamondSelection) {
            setInitialOption();
            setBackDiamondDetails(false);
        }
        (instanceData.screenManager as any).props.setStateManagerData(
          'currentScreen',
          'Diamond selection screen',
        );
        (instanceData.screenManager as any).props.setStateManagerData(
          'currentView',
          'Solitaire feed view',
        );
    }, [filter.options])

    useEffect(() => {
      if(filter.options.shape && filter.options.source){
        recommendData();
      }
      else{
        setRecommendedDataDefault();
      }
    }, [filter.options.shape,filter.options.source,filter.options.carat]);

    const tabsData: TabsProps[] = [
        {
            label: `All (${(table && table.tableAllData && totalCount > 0) ? ToLocalStringConvert(+totalCount) : 0})`,
            content: <Table isLoading={table.isLoading}
                            isFetching={table.isRefetching}
                            data={table.tableAllData}
                            error={table.error}
                            rowCount={totalCount}
                            enablePagination={true}
                            manualSorting={true}
                            sorting={tableSorting}
                            setSorting={setTableSorting}
                            instanceData={instanceData}
                     />,
            isIcon: false,
        },
        {
            label: `Recommended ${table && table.recommendedData.length > 0 ? `(${ToLocalStringConvert(+(table?.recommendedData.length))})` : ''}`,
            content: <Table isLoading={table.recommendedLoading}
                            data={table.recommendedData}
                            error={table.recommendedError}
                            instanceData={instanceData}
                            isGrouping={true}
                    />,
            isIcon: true,
            // iconName: "hk-sparkles"
        },{
            label: `Recent ${table && table.recentData && table.recentData.length > 0 ? `(${ToLocalStringConvert(+(table?.recentData.length))})` : ''}`,
            content: <Table isLoading={false}
                            data={table.recentData}
                            error={false}
                            instanceData={instanceData}
            />,
            isIcon: false,
            isNotShow:table.recentData.length < 1
        },
        {
            label: `Compare ${table && table.compareData && table.compareData.length > 0 ? `(${ToLocalStringConvert(+(table.compareData.length))})` : ''}`,
            content: <Table
              data={table.compareData}
              isLoading={false}
              error={false}
              instanceData={instanceData}
            />,
            isIcon: false,
            isNotShow:table.compareData.length < 1
        }
    ];

    useEffect(() => {
        if((table.compareData.length === 0) && (table.activeTab === 3)){
            setActiveTab(0);
        }
    }, [table.compareData])
    useEffect(()=>{
        if(!hasEffectRun.current){
            TooltipDataFetch(setTooltipDataLoading,SetTooltipDataSuccess,SetTooltipDataError);
        }
        return () => {
            hasEffectRun.current = true;
        }
    },[hasEffectRun])

    const handleRESETFilters = useCallback(() => {
        toggleScrollTop(false);
        if (table.activeTab === 1) setActiveTab(0);
        if(Object.keys(filter.afterRingSelectedOptions).length > 0){
            setInitialFilterAfterRingOptions(["Jenny Packham","Reve"].includes(ring.extraData.Label) ? true : false);
        }else {
            resetFilters()
        }
        setBackToSelectDiamondPage(false);
    }, [setBackToSelectDiamondPage,resetFilters,setActiveTab,table.activeTab,setInitialFilterAfterRingOptions,filter.afterRingSelectedOptions])


    return {
        tabs : tabsData,
        color,
        clarity,
        cut,
        fluorecence,
        polish,
        symmetry,
        source,
        handleRESETFilters,
        isMobileLayout
    }
}
export default useDiamond;
