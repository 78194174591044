import { useNavigate} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {PropsFromScreenManager, UseHeaderTabType} from "../../../types";
import {HKCustomize, HKDiamond, HKRing} from "../../Images";

const UseHeaderTab = ({diamond,ring,footer,setHeaderTabToSetting,setInitialOption,setDiamondTabIconTrue,setHeaderTabDefault,setSettingTabIconTrue,setIsShowFooter,instanceData:{screenManager},setInitialStartWithSettingData,setInitialDiamondOption,setBothTabIconTrue,openTab,setInitialFooter,setInitialFilter,setTableInitialState}:UseHeaderTabType & PropsFromScreenManager) => {
    const navigate = useNavigate();

    const setCenterTabData =useCallback((URLPath:string[])=>{
        switch(true) {
            case URLPath.includes('#diamond'):
                if(!(Object.keys(ring.options).length > 0)){
                    setHeaderTabDefault();
                } else {
                    setSettingTabIconTrue();
                }
                break;
            case URLPath.includes('#setting'):
                if(URLPath.includes('diamondId')){
                    setDiamondTabIconTrue('setting');
                } else {
                    setHeaderTabToSetting('setting');
                }
                break;
            case URLPath.includes('#dyo'):
                if(URLPath.includes('diamondId')){
                    setDiamondTabIconTrue('dyo');
                } else {
                    setHeaderTabToSetting('dyo');
                }
                break;
            default:
                break;
        }
    },[ring.options,diamond.details])

    useEffect(() => {
        const updatePathName:string[] = window.location.hash.split(/['/=']/);
        if(updatePathName){
            setCenterTabData(updatePathName);
        }
    }, []);

    useEffect(()=>{
        if(footer.toggle){
            if(window.location.hash.split(/['/=']/).includes('#diamond')){
                setBothTabIconTrue(['SELECT SETTING','SELECT DIAMOND']);
            }else{
                setBothTabIconTrue(['SELECT DIAMOND','SELECT SETTING']);
            }
        }
    },[footer.toggle])

    const handleChangePath =useCallback((key:string)=>{
        const currentPathName:string[] = window.location.hash.split(/['/=']/);
        setInitialOption();
        setInitialStartWithSettingData();
       if(key === 'setting'){
           if(!currentPathName.includes('#details') && currentPathName.includes('diamondId')){
                setDiamondTabIconTrue('setting');
                (screenManager as any).changeScreen({ viewName: 'setting', id: diamond.details.id,styleId: '' });
           }else{
               setHeaderTabToSetting('setting');
               setInitialDiamondOption();
               setInitialFilter();
               setTableInitialState();
               setIsShowFooter(false);
               (screenManager as any).changeScreen({ viewName: 'setting', id: null,styleId: '' });
           }
        }else{
           if(!currentPathName.includes('#details') && currentPathName.includes('diamondId')){
               setDiamondTabIconTrue('dyo');
               (screenManager as any).changeScreen({ viewName: 'dyo', id: diamond.details.id,styleId: 'RB001' });
           }else{
               setHeaderTabToSetting('dyo');
               setInitialDiamondOption();
               setInitialFilter();
               setTableInitialState();
               setIsShowFooter(false);
               (screenManager as any).changeScreen({ viewName: 'dyo', id: null,styleId: 'RB001' });
           }
        }

    },[setInitialOption,setInitialStartWithSettingData,setHeaderTabToSetting,navigate,diamond,screenManager,setInitialDiamondOption,setHeaderTabDefault,setDiamondTabIconTrue])

    const handleBack = useCallback(async (label:string) => {
        if(label === 'SELECT DIAMOND'){
            await setInitialOption();
            await setInitialStartWithSettingData();
            await setHeaderTabDefault();
            await (screenManager as any).changeScreen({ viewName: 'diamond', id: null,styleId: '' });
        }else {
            await setInitialFooter();
            await setTableInitialState();
            await setInitialDiamondOption();
            await setInitialFilter();
            await setHeaderTabToSetting('dyo');
            await (screenManager as any).changeScreen({ viewName: 'dyo', id: null,styleId: footer.styleID });
        }
    },[setInitialOption,setInitialStartWithSettingData,setHeaderTabDefault,screenManager,setInitialFooter,setInitialDiamondOption,setHeaderTabToSetting,footer.styleID,setInitialFilter,setTableInitialState])

    const tabData = [
        {
            heading :'Start With a Setting',
            imagePath:HKRing,
            imageAlt: "Start With a Setting",
            description:'Select a ring setting from our exclusive curation.',
            key:'setting',
            isVisible:openTab !== 'setting'
        },
        {
            heading :'Design Your Own',
            imagePath:HKCustomize,
            imageAlt: "Design Your Own",
            description:'Design your own engagement ring by customizing your ring’s style, setting, metal and so much more.',
            key:'dyo',
            isVisible:openTab !== 'dyo'
        },
    ]

    return {
        handleChangePath,
        tabData,
        handleBack
    }
}

export default UseHeaderTab;
