import React, { FC } from 'react';
import DialogBox from "../DialogBox";
import Icon from "../Icons";
import UseExitButton from "./useExitButton";
import classes from "./ExitButton.module.css";
import { PropsFromScreenManager } from '../../types';

export interface PropsForExitButton extends PropsFromScreenManager {
    // handleexit: () => void;
    previousPath:string
};

const ExitButton:FC<PropsForExitButton> = (props) => {
    const {isOpenDialog, openDialogBox, closeDialogBox, renderMenuContent, mobileMenu,handleExit} = UseExitButton(props);
    return (
        <>
            <div className={classes.helpWrap}>
                {/*{renderMenuContent()}*/}
                <div
                    className={`${classes.helpInner} ${
                        mobileMenu ? classes.active : ""
                    }`}
                >
                    {/*<div className={classes.link} onClick={()=>(screenManager as any).changeScreen({viewName:'help'})}>*/}
                    {/*    <Icon name="hk_help" className={classes.icon}/>*/}
                    {/*    <span className={classes.linkText}>Help</span>*/}
                    {/*</div>*/}
                    <button type={"button"} className={classes.link} onClick={openDialogBox}>
                        <Icon name={"hk_xmark"} className={classes.icon}/>
                        <span className={classes.linkText}>Exit</span>
                    </button>
                </div>
            </div>
            <DialogBox
                isOpen={isOpenDialog}
                closeModal={closeDialogBox}
                isIcon={true}
                title={"Do you want to exit from Ring Builder?"}
            >
                <div className={classes.dialogBody} onClick={closeDialogBox}>
                    <div className={classes.dialogDetail}>
                        <div className={classes.dialogText}>
                            <h2 className={classes.dialogTitle}>{'No, Continue with Ring Builder'}</h2>
                        </div>
                    </div>
                </div>
                <div className={classes.dialogBody} onClick={handleExit}>
                    <div className={classes.dialogDetail}>
                        <div className={classes.dialogText}>
                            <h2 className={classes.dialogTitle}>{'Yes, I want to exit'}</h2>
                        </div>
                    </div>
                </div>
            </DialogBox>
        </>
    )
}

export default ExitButton;