import React, { Fragment, useState } from 'react';
import classes from './LabDialog.module.css';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../Button';
import Icon from '../Icons';
import { GCAL, GIA, IGI } from '../Images';
import { GIADialogType } from '../../types';
import Loader from "../Loader/Loader";

const LabDialog = (props: GIADialogType) => {
  const { certificationName,allThumbnailCoordinates, certification } = props;
  const [open, setOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const labImage:{[key:string]:string} = {
    "GIA" : GIA,
    "IGI" : IGI,
    "GCAL" : GCAL,
  }
  return (
    <>
      {window.innerWidth < 1280 ||allThumbnailCoordinates.y ? <>
          <img
            src={labImage[certificationName]}
            className={classes.GIACertificate}
            style={{
              left: window.innerWidth >= 1440 ? 0 : `calc(${allThumbnailCoordinates.left}px + ${allThumbnailCoordinates.width}px - 2.5rem + 10px)`,
              top: window.innerWidth >= 1440 ? allThumbnailCoordinates.height : allThumbnailCoordinates.y - 60
            }}
            onClick={() => setOpen(true)}
          />
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-start justify-center p-4 text-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 -translate-y-14 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 -translate-y-14 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel
                      className={`${!imageLoaded ? classes.imgLoaded : ''} max-w-[1600px] 2xl:max-w-[1000px] xl:max-w-[800px] relative transform bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg`}>
                      <Dialog.Title className={'flex justify-end sticky top-0 bg-white p-2 border-b border-solid'}>
                        <Button
                          type={'button'}
                          className={classes.closeIcon}
                          onClick={() => setOpen(false)}
                        >
                          <Icon name={'hk_xmark'} className={"xl:!size-4"} />
                        </Button>
                      </Dialog.Title>
                      <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close</span>
                          {/*<Close className="h-6 w-6" aria-hidden="true" />*/}
                        </button>
                      </div>
                      <div className={`${!imageLoaded ? classes.imgItem : ''} sm:flex sm:items-start px-4 pb-4 sm:px-2 sm:pb-2`} >
                        <div className="max-w-[1600px] 2xl:max-w-[1000px] xl:max-w-[800px] text-center sm:ml-4 sm:mt-0 sm:text-left">
                            {
                              !imageLoaded && <Loader/>
                            }
                            <img
                              src={certification}
                              className={classes.certiImg}
                              onLoad={()=>setImageLoaded(true)}
                            />
                      </div>
                </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
        : null
      }
    </>
  );
};
export default LabDialog;
