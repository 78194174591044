import React, {Fragment, useMemo} from "react";
import {CheckIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon,} from "@heroicons/react/20/solid";
import {MRT_RowData, MRT_TableInstance} from "../../types";
import {Listbox, Transition} from "@headlessui/react";
import {useMediaQuery} from "react-responsive";
import {DOTS, usePagination} from "../../hooks/usePagination";

interface Props<TData extends MRT_RowData> {
  position?: "bottom" | "top";
  table: MRT_TableInstance<TData>;
}

export const MRT_TablePagination = <TData extends MRT_RowData>({
                                                                 position = "bottom",
                                                                 table,
                                                               }: Props<TData>) => {
  const {getState} = table;
  const {
    setPagination,
    reduxPagination,
    activeTab,
    pagination: {pageSize = 10, pageIndex = 0},
    paginationArray
  } = getState();
  // console.log(reduxPagination)
  const isMobileLayout = useMediaQuery({query: "(max-width: 549px)"});
  const totalRowCount = table.getRowCount();
  const numberOfPages = table.getPageCount();
  const lastRowIndex = Math.min(reduxPagination[activeTab].pageIndex * pageSize + pageSize, totalRowCount);
  const disableBack = reduxPagination[activeTab].pageIndex <= 0;
  const disableNext = lastRowIndex >= totalRowCount;
  const visiblePages = useMemo(() => {
    const totalVisiblePages = 5;
    const currentPage = reduxPagination[activeTab].pageIndex + 1;
    const totalPages = numberOfPages;
    let startPage = 0;
    let endPage;
    if (totalPages <= totalVisiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= 3) {
      startPage = 1;
      endPage = totalVisiblePages;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - totalVisiblePages + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }

    return Array.from({length: endPage - startPage + 1}, (_, index) => index + startPage);
  }, [pageIndex, numberOfPages]);
  const currentPage = reduxPagination[activeTab].pageIndex + 1;
  const paginationRange = usePagination({
    totalCount : totalRowCount,
    pageSize : reduxPagination[activeTab].pageSize,
    siblingCount: 2,
    currentPage
  });
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  // const [selected, setSelected] = useState(people[3])

  return (
    <React.Fragment>
      {paginationArray.length > 0 ? <div className={"headless_pagination-main"}>
        <div className="headless_pagination-child1">
          <div className={"headless_flex-gap sm:!gap-2 pageView text-[0.8125rem]"}>
            {/*<Listbox value={pageSize} onChange={
              (number) => {
                setPagination(activeTab, {pageSize: Number(number)})
                table.setPageSize(Number(number))
              }
            }>
              {({open}) => (
                <>
                  <Listbox.Label className="text-borderDark/70 ">Per Page</Listbox.Label>
                  <div className="relative min-w-20 md:min-w-16 cursor-pointer">
                    <Listbox.Button
                      className="relative w-full cursor-pointer bg-white py-1.5 pl-3 pr-8 md:pr-6 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-1 focus:ring-black sm:text-sm sm:leading-4 md:leading-5">
                      <span className="block truncate">{pageSize}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        className="bottom-[100%] absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {paginationArray.map((pageSize) => (
                          <Listbox.Option
                            key={pageSize}
                            className={({selected, active}) =>
                              classNames(
                                selected ? '!bg-borderGray text-black !cursor-pointer' : active ? '!bg-groupingBorder !cursor-pointer':'text-gray-900',
                                'relative text-[0.8125rem] cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={pageSize}
                          >
                            {({selected, active}) => (
                              <>
                                <span>{pageSize}</span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      'absolute inset-y-0 right-0 flex items-center pr-4 md:pr-2'
                                    )}
                                  >
                                    <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>*/}
          </div>
          <div className={"headless_flex-center"}>
            <button
              className={"headless_py-2 px-2 border border-borderDark/20 sm:border-borderDark/60 flex justify-center items-center size-[40px] md:size-[34px] sm:size-9 md:text-[13px]"}
              onClick={() => {
                table.previousPage();
                setPagination(activeTab, {
                  pageSize: reduxPagination[activeTab].pageSize,
                  pageIndex: reduxPagination[activeTab].pageIndex - 1
                })
              }}
              disabled={disableBack}
            >
              <ChevronLeftIcon height={20} width={20} />
            </button>
            {/* Numbered Pagination Buttons */}
            {isMobileLayout ? (
              <span
                className={'border -ml-[1px] border-borderDark/20 flex justify-center items-center md:h-9 px-2 opacity-70 whitespace-nowrap md:text-[13px]'}>{reduxPagination[activeTab].pageIndex + 1} / {numberOfPages}</span>
            ) : (
              <>
                {paginationRange?.map((pageNumber,index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        if((pageNumber as unknown as string) !== DOTS){
                          table.setPageIndex(pageNumber - 1);
                          setPagination(activeTab, {
                            pageSize: reduxPagination[activeTab].pageSize,
                            pageIndex: pageNumber - 1
                          })
                        }
                      }}
                      className={`${pageNumber === reduxPagination[activeTab].pageIndex + 1 ? '!text-black !border-black z-[1]' : ''} text-[0.8125rem] headless_py-2 px-2 border -ml-[1px] border-borderDark/20 flex justify-center items-center size-[40px] md:size-[34px] md:text-[13px]`}
                      disabled={pageNumber === reduxPagination[activeTab].pageIndex + 1}
                    >
                      {pageNumber}
                    </button>
                  )
                })}
              </>)}
            <button
              className={"headless_py-2 px-2 border -ml-[1px] border-borderDark/20 sm:border-borderDark/60 flex justify-center items-center size-[40px] md:size-[34px] sm:size-9 md:text-[13px] sm:text-black"}
              onClick={() => {
                table.nextPage();
                setPagination(activeTab, {
                  pageSize: reduxPagination[activeTab].pageSize,
                  pageIndex: reduxPagination[activeTab].pageIndex + 1
                })
              }}
              disabled={disableNext}
            >
              <ChevronRightIcon height={20} width={20} />
            </button>
          </div>
        </div>
      </div> : null}
    </React.Fragment>
  );
};
